<template>
  <div class="hour-statistics" ref="hourStatistics">
    <div class="form-area" ref="form">
      <analysis-form @getFormVal="onSubmit" :showCar="false" :showDriver="true">
        <el-button
          type="primary"
          size="small"
          :disabled="carArr.length === 0"
          :loading="downLoadStatus"
          @click="exportData"
        >导出</el-button>
      </analysis-form>
    </div>
    <car-table
      ref="pagination2"
      :height="tableHeight"
      :total="total"
      :tableData="carArr"
      @sendInfo="getPageInfo"
    />
  </div>
</template>
<script>
import analysisForm from "@/components/analysisForm/analysisFormNew.vue";
import { addExcelExport } from "@/api/lib/refreshAlarm.js";
import { queryDurationByPage } from "@/api/lib/gps-api.js";
import carTable from "./components/carTable.vue";
import { getCurent, formatDate } from '@/common/utils/index'
export default {
  components: {
    analysisForm,
    carTable,
  },
  data () {
    return {
      tableHeight: 0,
      total: 0,
      exportForm: {},
      carArr: [],
      downLoadStatus: false,
      form: {
        credentialsNos: null,
        companyId: null,
        beginTime: null,
        endTime: null,
        currentPage: 1,
        pageSize: 10,
      },

    }
  },
  destroyed () {
    window.removeEventListener("resize", this.computeHeight);
  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  methods: {
    // 计算表格高度
    computeHeight () {
      const wholeHeight = this.$refs.hourStatistics.clientHeight;
      const formHeight = this.$refs.form.clientHeight || 64;
      this.tableHeight = wholeHeight - formHeight - 40 - 30 - 10 - 24;
    },
    /**分页参数切换 */
    getPageInfo (params) {
      this.form.currentPage = params.currentPage;
      this.form.pageSize = params.pageSize;
      if (this.carArr.length === 0) return;
      this.getDataList();
    },
    // 提交
    onSubmit (value) {
      this.form = { ...this.form, ...value };
      this.form.currentPage = 1;
      this.getDataList();
    },
    getDataList () {
      const data = { ...this.form }
      queryDurationByPage(data).then(res => {
        if (res.code === 1000) {
          this.carArr = res.data.list || [];
          this.total = res.data.total || 0;
        } else {
          this.$message.warning(res.msg)
        }
        this.exportForm = {...data}
      })

    },
    //导出
    exportData (key) {
      this.downLoadStatus = true;
      delete this.exportForm.currentPage;
      delete this.exportForm.pageSize;
      let data = {
        baseUrl: "report",
        userId: sessionStorage.getItem("userId") || localStorage.getItem('userId'),
        createTime: formatDate(new Date()),
        fileName:`驾驶员驾驶时长统计-${getCurent()}`,
        filePath: null,
        fileStatus: 1,
        generateTime: null,
        queryParam: JSON.stringify(this.exportForm),
        queryPath:`/drivingDuration/drivingDurationExport`,
      };
      addExcelExport(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.downLoadStatus = false;
          this.$store.dispatch("setRefreshStatus", true);
        })
        .catch(() => {
          this.downLoadStatus = false;
        });
    },
  }
}
</script>
<style lang="scss" scoped>
.hour-statistics {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
}
.theme-project-gps {
  .hour-statistics {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>