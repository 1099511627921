<template>
  <div class="multilevel" v-if="tableData.length > 0">
    <el-table :data="tableData" :height="height + 36" style="width: 100%" ref="tableName">
      <el-table-column type="index" width="70" label="序号"></el-table-column>
      <el-table-column prop="companyName" label="所属企业" width="180" show-overflow-tooltip></el-table-column>
      <el-table-column prop="driverName" label="驾驶员姓名"></el-table-column>
      <el-table-column
        v-for="(item, index) in tableData[0].detailList"
        :key="index"
        :label="item.statisticsDate"
        :width="tableData[0].detailList.length > 5 ? '130' : ''"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          {{scope.row.detailList[index].duration
          }}
        </template>
      </el-table-column>
      <el-table-column prop="total" :width="tableData[0].detailList.length > 5 ? '140' : ''" label="驾驶员总时长">
        <template slot-scope="scope">{{queryDate(scope.row.total)}}</template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="pageObj.pageSize"
        :current-page="pageObj.currentPage"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    height: {
      type: Number,
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
    },
  },
  watch: {
    height (val, oldVal) {
      setTimeout(() => {
        this.setTableHeight(
          this.tableData.length > 0 ? this.tableData[0].detailList.length : 0,
          this.tableData.length > 0 ? this.tableData.length : 0
        )
      }, 200);
    },
    tableData(val){
      this.$nextTick(()=>{
        this.$refs.tableName.doLayout();
      })
    }
  },

  data () {
    return {
      pageObj: {
        currentPage: 1,
        pageSize: 10,
      },
      tableHeight: 0,
    };
  },
  computed: {
    //将秒转化为时分秒
    queryDate () {
      return function (endTime) {
        let secondTime = parseInt(endTime)
        let min = 0// 初始化分
        let h = 0// 初始化小时
        let result = ''
        if (secondTime > 60) {
          min = parseInt(secondTime / 60)
          secondTime = parseInt(secondTime % 60)
          if (min > 60) {
            h = parseInt(min / 60)
            min = parseInt(min % 60) 
          }
        }
        // result = `${h.toString().padStart(2, '0')}:${min.toString().padStart(2, '0')}:${secondTime.toString().padStart(2, '0')}`
        result = `${h}时${min}分${secondTime}秒`
        return result
      }
    },
  },
  methods: {
    handleSizeChange (val) {
      this.pageObj.pageSize = val;
      this.$emit("sendInfo", this.pageObj);
    },
    handleCurrentChange (val) {
      this.pageObj.currentPage = val;
      this.$emit("sendInfo", this.pageObj);
    },
    reset () {
      this.pageObj.currentPage = 1;
      this.pageObj.pageSize = 10;
    },
    setTableHeight (Xlength, Ylength) {
      setTimeout(() => {
        this.tableHeight = this.height
        if (Xlength > 7) {
          document
            .querySelector('.user-login .el-table')
            .classList.add('el-table--scrollable-x')
          document.querySelector(
            '.user-login .el-table--scrollable-x .el-table__body-wrapper'
          ).style = `height:${this.height - 23}px`
        }
      }, 300)

      if (Ylength > 10) {
        setTimeout(() => {
          document
            .querySelector('.user-login .el-table')
            .classList.add('el-table--scrollable-y')
          document.querySelector(
            '.user-login .el-table .el-table__body-wrapper'
          ).style = `height:${this.height - 23}px`
        }, 500)
      }
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
